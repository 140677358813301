import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageTitleBox from "../components/PageTitleBox"

const IndexPage = () => (
  <Layout>
    <Seo />
    <PageTitleBox title="お申し込み完了" />
    <div className="container">
      <p class="mb-4">
        お申し込みくださりありがとうございました。<br />
        5日以内にお返事いたしますので、いましばらくお待ちください。
      </p>
    </div>
  </Layout>
)

export default IndexPage
